import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vincent/Documents/Develop/Web/PersonalWebsite/website/src/components/layout-markdown.tsx";
import { CoworkingPics } from "../components/coworking-pics";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <CoworkingPics mdxType="CoworkingPics" />
    <p>{`Hi there, and welcome! Do you like meeting interesting people, but are short on time? Well, me too! That's why I'm `}<strong parentName="p">{`opening my flat in `}<a parentName="strong" {...{
          "href": "https://goo.gl/maps/vPFq3GH1eoeTfWYK6"
        }}>{`La Sagrera, Barcelona`}</a>{` to host small co-working sessions`}</strong>{` (for free). We get together, get a lot of work done, enjoy some food together and can enjoy the terrace overlooking the park afterwards. The idea is to get together `}<strong parentName="p">{`small groups of people that might want to get to know each other`}</strong>{`, a bit more curated than in your typical co-working spaces. Oh, and there's the obvious stuff: 600mbps+ internet, spaces to have calls and a mini gym.`}</p>
    <p>{`So, a bit more about me! I'm primarily interested in how humanity can flourish. This includes topics like new ways of working together, to evolving education in an exponential society, how to stay deeply connected as humans, community, collective intelligence and so on. Technology is a medium to experiment with these topics, not a goal in itself. I prefer new and deeper insights: "What are the fundamental trade-offs between centralization and decentralization?" and "Is it possible to cultivate curiosity a large scale?" rather than "Look at this thing ChatGPT did!" and "Did you see new hot framework X? It's going to change everything!" Deep thinkers, educators, artists, craftspeople, philosophers, all are welcome!`}</p>
    <p>{`I work as CTO at `}<a parentName="p" {...{
        "href": "https://memex.garden/"
      }}>{`Memex`}</a>{` developing a collaborative research tool with the goal of allowing people to build on each others' research, rather than starting from zero at a Google search each time when diving into a new topic. Also, I'm developing a new UI development methodology with the goal of reducing digital product development time and costs 10x by allowing designers to do work that right now can only be done by developers.`}</p>
    <p>{`What do you think? Might we have some interesting things to share over a drink or two? `}<a parentName="p" {...{
        "href": "mailto:vincent@youapt.eu?subject=Coworking%20session"
      }}>{`Shoot me an e-mail`}</a>{` and we can figure out a time that works for all of us. Feel free to bring your friends or propose a co-working session together with someone you'd like to meet!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      